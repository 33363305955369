import { render, staticRenderFns } from "./PayBox.vue?vue&type=template&id=3b0e61b0&scoped=true&"
import script from "./PayBox.vue?vue&type=script&lang=js&"
export * from "./PayBox.vue?vue&type=script&lang=js&"
import style0 from "./PayBox.vue?vue&type=style&index=0&id=3b0e61b0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b0e61b0",
  null
  
)

export default component.exports