<template>
    <span>
        <span class="at-tips" @click="showTipsModal = true">
            <a-icon type="question-circle"/>
        </span>

        <a-modal :width="width" v-model="showTipsModal" :title="null" :footer="null">
            <slot/>
        </a-modal>
    </span>
</template>

<script>
export default {
    name: "AtTips",
    props: {
        width: {
            type: String,
            default() {
                return '1000px';
            }
        }
    },
    data() {
        return {
            showTipsModal: false
        }
    }
}
</script>

<style scoped lang="less">
.at-tips {
    cursor: pointer;
}
</style>