<template>
    <div class="upload-img" :class="{'has-image':!!files.length}">


        <div class="images-list">
            <template v-for="(image,key) in files">
                <div :key="key" class="image-container" :style="{backgroundImage:`url(${image})`}">
                    <div class="image-opera">
                        <a-button v-if="!disabled" @click="delImage(key)" type="link">
                            <span class="upload-del"></span>
                        </a-button>
                        <a-button @click="preview(key)" type="link">
                            <span class="upload-fd"></span>
                        </a-button>
                    </div>
                </div>
            </template>

            <template v-if="showUploader && (!(files.length && !multiple )) && ((maxLength -files.length) > 0 )">
                <a-upload
                        name="image"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :show-upload-list="false"
                        :action="fullAction"
                        :before-upload="beforeUpload"
                        @change="handleChange"
                        :disabled="disabled"
                >
                    <div>
                        <a-icon class="up-icon" :type="loading ? 'loading' : 'upload'"/>
                    </div>
                </a-upload>
            </template>
        </div>

        <template v-if="showPreviewModal">

            <a-modal class="img-modal" v-model="showPreviewModal" :footer="null">
                <img @click="showPreviewModal = false" :src="previewImage" alt="avatar"/>
            </a-modal>

        </template>

    </div>
</template>

<script>
import api from '@/repo/api';
import auth from "../auth";

export default {
    name: "UploadImage",
    model: {
        event: "change",
        prop: "imageUrl"
    },
    props: {
        imageUrl: {
            type: [String, Array]
        },
        disabled: Boolean,
        usage: String,
        multiple: Boolean,
        action: {
            type: String,
            default() {
                return api.colUrl('/image/upload')
            }
        },
        maxLength: {
            type: Number,
            default() {
                return 9;
            }
        },
        done: Function
    },
    watch: {
        action() {
            this.buildFullAction()
        },
        imageUrl() {
            this.initFiles()
        }
    },
    data() {
        return {
            loading: false,
            showPreviewModal: false,
            fullAction: '',
            files: [],
            previewImage: '',
            showUploader: true,
        }
    },
    mounted() {
        this.buildFullAction()
        this.initFiles()
    },
    methods: {
        initFiles() {
            if (typeof this.imageUrl === 'string') {
                this.files = this.imageUrl ? [this.imageUrl] : []
            } else {
                this.files = this.imageUrl || []
            }
        },
        buildFullAction() {
            let appId = localStorage['mini_app_id'];

            if (this.action.indexOf('?') === -1) {

                this.fullAction = this.action + '?token=' + auth.getToken() + '&_mini_app_id=' + appId + '&usage=' + this.usage

                return;
            }

            this.fullAction = this.action + '&token=' + auth.getToken() + '&_mini_app_id=' + appId + '&usage=' + this.usage
        },
        delImage(idx) {
            if (this.disabled) return;

            let url = this.files.splice(idx, 1);

            if (this.multiple) {
                this.$emit('change', this.files)
            } else {
                this.$emit('change', this.files[0] || '')
            }

            this.$emit('del', url[0])
        },
        preview(idx) {
            this.previewImage = this.files[idx]
            this.showPreviewModal = true
        },
        handleChange(info) {
            if (info.file.status === 'uploading') {
                this.loading = true;
                return;
            }
            if (info.file.status === 'done') {

                let response = info.file.response;

                if (response.code !== 0) {
                    this.loading = false;
                    return this.$message.error(response.msg)
                }

                let url = ''

                if (typeof this.done === 'function') {
                    url = this.done(response.data)
                } else {
                    url = response.data

                    if (!this.multiple) {

                        this.$emit('change', url)

                    } else {

                        this.files.push(url)

                        this.$emit('change', this.files)

                    }

                    this.showUploader = false

                    setTimeout(() => {

                        this.showUploader = true

                    }, 100)
                }

                this.loading = false;

            }
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('你只能上传jpg,png文件!');
            }
            const isLt1M = file.size / 1024 / 1024 < 2;
            if (!isLt1M) {
                this.$message.error('图片必须小于2M!');
            }
            return isJpgOrPng && isLt1M;
        },
    }
}
</script>

<style lang="less" scoped>
.upload-img {
    line-height: 0;
}

.up-icon {
    font-size: 24px;
}

.avatar-uploader {
    width: 110px;
}

.image-opera {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    line-height: 0;
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    position: relative;
    margin-right: 8px;
    margin-bottom: 8px;
    width: 102px;
    height: 102px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.upload-del, .upload-fd {
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("/assets/icons/upload-del.png");
    background-size: 20px;
}

.upload-fd {
    background-image: url("/assets/icons/upload-fd.png");
}

.img-modal {
    /deep/ .ant-modal {
        top: 0;
        width: 100vw !important;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.images-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
}

</style>